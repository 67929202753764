export default class permissionModel {
  constructor() {
    this.list = [
      {
        name: 'Analyse et validation',
        key: 'Analyse et validation',
        value: ['Modifier', 'Supprimer', 'Valider', 'Télecharger']
      },
      {
        name: 'Analyse et validation TH',
        key: 'Analyse et validation TH',
        value: ['Modifier', 'Supprimer', 'Valider', 'Télecharger']
      },
      {
        name: 'Insertion BDD',
        key: 'Insertion BDD',
        value: ['Upload']
      },
      {
        name: 'Validation salaires',
        key: 'Validation salaires',
        value: ['Valider', 'Télecharger']
      },
      {
        name: 'Validation indépendants',
        key: 'Validation indépendants',
        value: ['Modifier', 'Valider', 'Télecharger']
      },
      {
        name: 'Suivi indépendants',
        key: 'Suivi indépendants',
        value: ['Modifier', 'Télecharger']
      },

      {
        name: 'Récapitulatif commerciaux par zone',
        key: 'Récapitulatif commerciaux par zone',
        value: ['Télecharger']
      },
      {
        name: 'Gestion des appels à facturation régie',
        key: 'Gestion des appels à facturation régie',
        value: ['Modifier', 'Valider', 'Télecharger']
      },
      {
        name: 'Liste des documents',
        key: 'Liste des documents',
        value: ['Modifier', 'Télecharger', 'Supprimer']
      },
      {
        name: 'Coût des régies',
        key: 'Coût des régies',
        value: ['Télecharger']
      },
      {
        name: 'Suivi des régies',
        key: 'Suivi des régies',
        value: ['Modifier', 'Télecharger']
      },
      {
        name: 'Récapitulatif régie par zone',
        key: 'Récapitulatif régie par zone',
        value: ['Télecharger']
      },
      {
        name: 'Statistique',
        key: 'Statistique',
        value: []
      },
      {
        name: 'Suivi de paiement th',
        key: 'Suivi de paiement th',
        value: ['Modifier', 'Configurer', 'Télecharger']
      },
      {
        name: 'Suivi des paiement Obligé',
        key: 'Suivi des paiement Obligé',
        value: ['Modifier', 'Télecharger']
      },
      {
        name: 'Gestions des appels à paiement Obligé',
        key: 'Gestions des appels à paiement Obligé',
        value: [
          'Modifier',
          'Télecharger',
          'Actualiser',
          'Upload',
          'Créer',
          'Envoyer email'
        ]
      },
      {
        name: 'Validation de paie commerciaux Th',
        key: 'Validation de paie commerciaux Th',
        value: ['Modifier', 'Valider', 'Télecharger']
      },
      {
        name: 'Validation des AAF commerciaux',
        key: 'Validation des AAF commerciaux',
        value: ['Modifier', 'Valider', 'Télecharger']
      },
      {
        name: 'Suivi de paiement des Indépendants Th',
        key: 'Suivi de paiement des Indépendants Th',
        value: ['Modifier', 'Télecharger']
      },
      {
        name: 'Récapitulatif Commemciaux par filiale Th',
        key: 'Récapitulatif Commemciaux par filiale Th',
        value: ['Télecharger']
      },
      {
        name: 'Suivi des acomptes',
        key: 'Suivi des acomptes',
        value: ['Supprimer']
      },
      {
        name: 'Gestion des AAF régie Th',
        key: 'Gestion des AAF régie Th',
        value: ['Modifier', 'Valider', 'Télecharger']
      },
      {
        name: 'Suivi des régies Th',
        key: 'Suivi des régies Th',
        value: ['Modifier', 'Télecharger']
      },
      {
        name: 'Récapitulatif régie par filiale Th',
        key: 'Récapitulatif régie par filiale Th',
        value: ['Télecharger']
      },
      {
        name: 'Frais BDD',
        key: 'Frais BDD',
        value: ['Télecharger', 'Modifier']
      },
      {
        name: 'Gestion global des frais',
        key: 'Récapitulatif gestion des frais',
        value: [
          'Télecharger',
          'Créer',
          'Modifier',
          'Actualiser',
          'Envoyer email'
        ]
      },
      {
        name: 'Gestion Commercial Cactus',
        key: 'Gestion Commercial Cactus',
        value: [
          'Télecharger',
          'Créer',
          'Modifier',
          'Actualiser',
          'Envoyer email'
        ]
      },
      {
        name: 'Gestion Bureau Etude',
        key: 'Gestion Bureau Etude',
        value: ['Télecharger', 'Créer', 'Modifier', 'Envoyer email']
      },
      {
        name: 'Gestion Compta',
        key: 'Gestion Compta',
        value: [
          'Télecharger',
          'Créer',
          'Modifier',
          'Supprimer',
          'Envoyer email'
        ]
      },
      {
        name: 'Gestion RH',
        key: 'Gestion RH',
        value: [
          'Télecharger',
          'Créer',
          'Modifier',
          'Supprimer',
          'Envoyer email'
        ]
      },
      {
        name: 'Gestion Loyer EI - SCI',
        key: 'Gestion Invest',
        value: [
          'Télecharger',
          'Créer',
          'Modifier',
          'Supprimer',
          'Envoyer email'
        ]
      },
      {
        name: 'Facture libre',
        key: 'Facture libre',
        value: ['Télecharger', 'Créer', 'Modifier', 'Dupliquer', 'Avoir']
      },
      {
        name: 'Statistiques générales',
        key: 'StatisticsGeneral',
        value: []
      },
      {
        name: 'Gestion informatique WA',
        key: 'Gestion informatique web abondance',
        value: [
          'Télecharger',
          'Créer',
          'Modifier',
          'Actualiser',
          'Envoyer email'
        ]
      },
      {
        name: 'Gestion Interne IHS',
        key: 'Gestion Interne IHS',
        value: ['Télecharger', 'Créer', 'Modifier', 'Envoyer email']
      },
      {
        name: 'Gestion des sociétés',
        key: 'Gestion des sociétés',
        value: []
      },
      {
        name: 'Gestion des sociétés SCI',
        key: 'Gestion des sociétés SCI',
        value: []
      },
      {
        name: 'Gestion des templates',
        key: 'Gestion des templates',
        value: []
      },
      {
        name: 'Répartition des zones',
        key: 'Répartition des zones',
        value: []
      },
      {
        name: 'Gestion des organismes',
        key: 'Gestion des organismes',
        value: []
      },
      // {
      //   name: 'Gestion des utilisateurs',
      //   key: 'Gestion des utilisateurs',
      //   value: []
      // },
      {
        name: 'Gestion des vues',
        key: 'Gestion des vues',
        value: []
      },
      {
        name: 'Gestion des variable',
        key: 'Gestion des variable',
        value: []
      },
      {
        name: 'Gestion des tarifs régie',
        key: 'Gestion des tarifs régie',
        value: []
      },
      {
        name: 'Gestion des variables frais',
        key: 'Gestion des variables frais',
        value: []
      },
      {
        name: 'Gestion des variables responsable filiale',
        key: 'Gestion des variables responsable filiale',
        value: []
      },
      {
        name: 'Gestion des catégories',
        key: 'Gestion des catégories',
        value: []
      },
      {
        name: 'Gestion des produits',
        key: 'Gestion des produits',
        value: []
      },
      {
        name: 'Export Globale',
        key: 'export globale',
        value: []
      }
    ];
  }

  static create() {
    return new permissionModel();
  }
}
