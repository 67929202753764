<template>
  <div class="visibility-user">
    <div class="permission-hearder">
      <div class="title">Permissions</div>
      <div v-if="getLoadingPermissions" class="chargement-message-text ml-2">
        Chargement en cours
      </div>

      <b-list-group class="custom-list-group">
        <b-list-group-item
          button
          v-for="permission in getAllPermissions"
          :key="permission.name"
          @click.prevent="handleClickPermission(permission)"
        >
          <div class="list-item">
            <div
              class="icon"
              v-if="
                selectedPermission && selectedPermission.name == permission.name
              "
            >
              <font-awesome-icon icon="check" />
            </div>
            <div>
              {{ permission.name }}
            </div>
          </div>
          <b-dropdown
            class="drop-down-list-item"
            size="lg"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            text="..."
          >
            <b-dropdown-item @click.stop="handleUpdatePermission(permission)">
              <font-awesome-icon class="icon" icon="pencil-alt" />Renommer
            </b-dropdown-item>
            <b-dropdown-item @click.stop="handleDeletePermission(permission)">
              <font-awesome-icon
                class="icon"
                icon="trash-alt"
              />Supprimer</b-dropdown-item
            >
          </b-dropdown>
        </b-list-group-item>
      </b-list-group>
    </div>
    <div class="permission-body">
      <div class="permission-user">
        <div class="title">Utilisateurs</div>

        <div class="permission-select-component" v-if="selectedPermission">
          <multiselect
            v-model="selectedUsers"
            :options="ComputedListUser"
            placeholder="Rechercher l'utilisateur"
            :multiple="true"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            label="full_name"
            track-by="id"
            :preselect-first="false"
            :disabled="loadingUser"
            :loading="loadingUserWithoutPermission"
          >
          </multiselect>
          <div class="position-btn">
            <b-button
              :disabled="loadingUser"
              v-if="selectedUsers.length"
              class="config-coef-btn "
              variant="secondary"
              @click.prevent="
                loadingUser == false ? handleSelectUsers(selectedUsers) : null
              "
              >Enregistrer</b-button
            >
          </div>
        </div>
        <div v-if="loadingUser" class="chargement-message-text ml-2">
          Chargement en cours
        </div>
        <b-list-group
          class="custom-list-group"
          v-if="selectedPermission && selectedPermission.users"
        >
          <b-list-group-item
            v-for="user in selectedPermission.users"
            :key="user.id"
          >
            <div>
              <div>{{ user.name }}</div>
              <div class="mail-item">{{ user.email }}</div>
            </div>
            <div class="icon-times">
              <font-awesome-icon
                icon="times"
                @click.prevent="
                  loadingUser == false ? handleDeleteUser(user) : null
                "
              />
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
      <div class="permission-interface">
        <div class="title">Interfaces</div>

        <div class="permission-select-component" v-if="selectedPermission">
          <multiselect
            v-model="selectedInterfaces"
            :options="ComputedListInterface"
            placeholder="Rechercher un interface"
            :multiple="true"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            label="name"
            track-by="name"
            :preselect-first="false"
            :disabled="loadingInterface"
          >
          </multiselect>
          <div class="position-btn">
            <b-button
              :disabled="loadingInterface"
              v-if="selectedInterfaces.length"
              class="config-coef-btn "
              variant="secondary"
              @click.prevent="
                loadingInterface == false
                  ? handleSelectInterfaces(selectedInterfaces)
                  : null
              "
              >Enregistrer</b-button
            >
          </div>
        </div>
        <div v-if="loadingInterface" class="chargement-message-text ml-2">
          Chargement en cours
        </div>
        <b-list-group
          class="custom-list-group"
          v-if="selectedPermission && selectedPermission.pages"
        >
          <b-list-group-item
            v-for="(value, name) in selectedPermission.pages"
            :key="name"
            @click.prevent="handleClickInterface({ value: value, name: name })"
          >
            <div>
              <span
                class="icon"
                v-if="
                  selectedPermission &&
                    selectedInterface &&
                    selectedInterface.name == name
                "
              >
                <font-awesome-icon icon="check" />
              </span>
              {{ name | filterInterfaceName(getListePermission) }}
            </div>
            <div class="icon-times">
              <font-awesome-icon
                icon="times"
                @click.prevent="
                  loadingInterface == false ? handleDeleteInterface(name) : null
                "
              />
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
      <div class="permission-autorisation">
        <div class="title">Autorisations</div>

        <div
          v-if="
            selectedPermission &&
              selectedInterface &&
              selectedInterface.name &&
              selectedInterface.value
          "
          class="mt-4"
        >
          <b-form-group>
            <b-form-checkbox-group
              switches
              stacked
              id="checkbox-group-1"
              v-model="selectedInterface.value"
              :options="computedListPermission"
              name="flavour-1"
            ></b-form-checkbox-group>
          </b-form-group>
          <div v-if="loadingAutorisation" class="chargement-message-text ml-2">
            Chargement en cours
          </div>
          <div class="position-btn">
            <b-button
              v-if="selectedInterface"
              class="config-coef-btn "
              variant="secondary"
              @click.prevent="
                loadingAutorisation == false ? handleUpdateAutorisation() : null
              "
              >Enregistrer</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <!--  Delete Permission Modal -->
    <b-modal
      ref="PermissionDeleteModal"
      id="PermissionDeleteModal"
      no-close-on-backdrop
      :hide-footer="true"
      title="Supprimer une permission "
      @hidden="hideModal('PermissionDeleteModal')"
    >
      <p>
        Êtes-vous certain de vouloir supprimer la permission
        <strong class="name">
          {{
            selectedPermissionToDelete ? selectedPermissionToDelete.name : ''
          }}
        </strong>
        ?
      </p>
      <div class="form-modal-custom-style">
        <div class="messageError">
          <div v-if="getErrorPermission" class="error">
            <ul v-if="Array.isArray(getErrorPermission)">
              <li v-for="(e, index) in getErrorPermission" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getErrorPermission }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button
            class="button-valide-style "
            @click.prevent="handleModalSupprimerValider"
          >
            <span>
              Confirmer
            </span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- END Delete Permission Modal -->
    <!-- Update Name Permission Modal -->
    <b-modal
      id="UpdateNamePermissionModal"
      ref="UpdateNamePermissionModal"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap"
    >
      <div class="hader mb-3">
        <div class="titleSetting">Renommer un ensemble d'autorisations</div>

        <div
          class="iconClose"
          @click.prevent="hideModal('UpdateNamePermissionModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="submitUpdateNamePermission"
            class="form-modal-custom-style"
          >
            <b-form-group
              label="Nom"
              label-for="Nom"
              class="input-modal-champ"
              v-if="
                selectedPermissionToUpdate && selectedPermissionToUpdate.name
              "
            >
              <b-form-input
                id="Nom"
                v-model="selectedPermissionToUpdate.name"
                required
              ></b-form-input>
            </b-form-group>

            <div class="messageError mt-3">
              <div v-if="getErrorPermission" class="error">
                {{ getErrorPermission }}
              </div>
            </div>
            <div class="actionModel">
              <b-button type="submit" class="button-valide-style ">
                <span
                  >Enregistrer
                  <div v-if="getLoadingPermissions" class="loading ml-2">
                    <div class="spinner-border" role="status"></div></div
                ></span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <!-- END Update Name Modal-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import permissionModel from '../../../models/permisionModel';
export default {
  data() {
    return {
      selectedPermission: null,
      selectedPermissionToDelete: null,
      selectedPermissionToUpdate: null,
      OldPermission: null,
      selectedUsers: [],
      selectedInterfaces: [],
      selectedInterface: null,
      loadingUser: false,
      loadingInterface: false,
      loadingAutorisation: false,
      getListePermission: []
    };
  },
  methods: {
    ...mapActions([
      'getAllPermission',
      'getUsersWithoutPermission',
      'updateNamePermission',
      'updatePermission',
      'deletePermission'
    ]),
    resetModal() {
      this.selectedPermissionToDelete = null;
      this.selectedPermissionToUpdate = null;
      this.OldPermission = null;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    handleClickPermission(permission) {
      this.getUsersWithoutPermission();
      this.selectedPermission = permission;
      this.selectedInterface = null;
    },
    handleUpdatePermission(permission) {
      this.selectedPermissionToUpdate = { ...permission };
      this.OldPermission = permission;
      this.$refs['UpdateNamePermissionModal'].show();
    },
    handleDeletePermission(permission) {
      this.selectedPermissionToDelete = permission;

      this.$refs['PermissionDeleteModal'].show();
    },
    async submitUpdateNamePermission() {
      const response = await this.updateNamePermission({
        permission: this.selectedPermissionToUpdate,
        oldPermission: this.OldPermission
      });
      if (response) {
        this.hideModal('UpdateNamePermissionModal');
      }
    },
    async handleModalSupprimerValider() {
      const response = await this.deletePermission(
        this.selectedPermissionToDelete
      );
      if (response) {
        if (
          this.selectedPermission &&
          this.selectedPermission.name &&
          this.selectedPermission.name == this.selectedPermissionToDelete.name
        ) {
          this.selectedPermission = null;
        }

        this.hideModal('PermissionDeleteModal');
      }
    },
    handleClickInterface(item) {
      this.selectedInterface = item;
      this.selectedInterface.value = this.selectedPermission.pages[
        this.selectedInterface.name
      ];
    },
    async handleDeleteUser(user) {
      this.loadingUser = true;
      this.selectedPermissionToUpdate = { ...this.selectedPermission };
      this.selectedPermissionToUpdate.users = this.selectedPermissionToUpdate.users.filter(
        item => item.id != user.id
      );
      await this.updatePermission({
        permission: this.selectedPermissionToUpdate,
        oldPermission: this.selectedPermission
      });
      this.getUsersWithoutPermission();
      this.loadingUser = false;
    },
    async handleSelectUsers(usersToAdd) {
      this.loadingUser = true;
      this.selectedPermissionToUpdate = { ...this.selectedPermission };
      this.selectedPermissionToUpdate.users = [
        ...this.selectedPermissionToUpdate.users,
        ...usersToAdd
      ];

      const response = await this.updatePermission({
        permission: this.selectedPermissionToUpdate,
        oldPermission: this.selectedPermission
      });
      if (response) {
        this.getUsersWithoutPermission();
        this.selectedUsers = [];
      }

      this.loadingUser = false;
    },

    async handleDeleteInterface(name) {
      this.loadingInterface = true;
      this.selectedPermissionToUpdate = { ...this.selectedPermission };

      let old = this.selectedPermission;

      let filterObject = {};
      let filterObjectDuplicated = this.selectedPermissionToUpdate;
      Object.keys(this.selectedPermissionToUpdate.pages).map(function(
        key,
        value
      ) {
        if (key != name) {
          filterObject[key] = filterObjectDuplicated.pages[key];
        }
      });
      this.selectedPermissionToUpdate.pages = filterObject;
      const response = await this.updatePermission({
        permission: this.selectedPermissionToUpdate,
        oldPermission: old
      });
      if (response) {
        this.selectedPermissionToUpdate = null;

        if (name == this.selectedInterface.name) {
          this.selectedInterface = null;
        }
      }

      this.loadingInterface = false;
    },
    async handleSelectInterfaces(interfacesToAdd) {
      this.loadingInterface = true;
      this.selectedPermissionToUpdate = { ...this.selectedPermission };
      interfacesToAdd.map(
        item => (this.selectedPermissionToUpdate.pages[item.key] = [])
      );
      const response = await this.updatePermission({
        permission: this.selectedPermissionToUpdate,
        oldPermission: this.selectedPermission
      });
      if (response) {
        this.selectedInterfaces = [];
      }

      this.loadingInterface = false;
    },
    async handleUpdateAutorisation() {
      this.loadingAutorisation = true;
      const old = { ...this.selectedPermission };
      this.selectedPermission.pages[
        this.selectedInterface.name
      ] = this.selectedInterface.value;
      await this.updatePermission({
        permission: this.selectedPermission,
        oldPermission: old
      });
      this.loadingAutorisation = false;
    }
  },
  computed: {
    ...mapGetters([
      'getAllPermissions',
      'usersWithoutPermission',
      'getLoadingPermissions',
      'getErrorPermission',
      'loadingUserWithoutPermission'
    ]),
    ComputedListUser: function() {
      return this.usersWithoutPermission;
      // .filter(user => {
      //   if (this.selectedPermission.users != null) {
      //     return (
      //       this.selectedPermission.users.findIndex(
      //         item => item.id === user.id
      //       ) < 0
      //     );
      //   }
      // });
    },
    computedListPermission: function() {
      let table = this.getListePermission.filter(
        item => item.key == this.selectedInterface.name
      );
      return table[0].value;
    },
    ComputedListInterface: function() {
      return this.getListePermission.filter(page => {
        if (this.selectedPermission.pages) {
          return (
            Object.keys(this.selectedPermission.pages).findIndex(
              item => item === page.key
            ) < 0
          );
        }
      });
    }
  },
  filters: {
    filterInterfaceName: function(value, list) {
      const table = list.filter(item => item.key == value);
      if (table) {
        return table[0].name;
      } else {
        return value;
      }
    }
  },
  components: {
    Card: () => import('../../component/card')
  },
  mounted() {
    this.getListePermission = permissionModel.create().list;
    this.getAllPermission();
    this.getUsersWithoutPermission();
  }
};
</script>

<style lang="scss" scoped>
.visibility-user {
  margin-top: 10px;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5c7;
  padding: 10px;
  border-radius: 15px;
  .title {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    color: #2a2a2a;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 600;
  }

  .custom-list-group {
    overflow-y: auto;
    max-height: calc(100vh - 330px);
    height: calc(100vh - 330px);
  }
  .permission-hearder,
  .permission-body {
    height: 100%;
    padding: 5px;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 15px;
  }
  .permission-hearder {
    width: 25%;
    margin-right: 10px;
  }
  .permission-body {
    width: 75%;
    display: flex;
    .permission-select-component {
      margin-bottom: 10px;
    }
    .permission-user,
    .permission-interface {
      width: 37%;
      padding: 5px;
    }
    .permission-autorisation {
      width: 25%;
      padding: 5px;
    }
  }
  .position-btn {
    position: relative;
    text-align-last: center;
    padding-top: 6px;
    .config-coef-btn {
      border-radius: 5px;
    }
  }
}
</style>
<style lang="scss">
.visibility-user {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #4d4bac !important;
    background-color: #4d4bac !important;
  }
  .multiselect__tag {
    background: #4d4bac;
  }
  .multiselect__tag-icon:after {
    color: #ffffff;
  }
  #checkbox-group-1 {
    text-transform: capitalize;
    margin: auto;
    display: table;
  }
}
</style>
